import styled from "styled-components";

export const Header = styled.header`
  background-color: #000000;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: #3d3d3d;
`;

export const Body = styled.div`
  align-items: center;
  background-color: #000000;
  color: #3d3d3d;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: calc(100vh - 70px);
`;

export const Image = styled.img`
  height: 200px;
  margin-bottom: 16px;
  pointer-events: none;
  width:300px;
`;

export const Link = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: #3d3d3d;
  margin-top: 10px;
`;

export const Button = styled.button`
  background-color: #3d3d3d;
  border: none;
  border-radius: 9px;
  color: #000000;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  margin: 0px 10px;
  padding: 9px 9px;

  ${props => props.hidden && "hidden"} :focus {
    border: none;
    outline: none;
  }
`;
